<template>
  <div class="mini-wides ma-auto mb-5">
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <h1 class="mb-4">
            Download your information
          </h1>
          <p>You can request a .zip file containing HTML files of your {{ brandName }} activity, info that you have shared on your profile, interactions with others and more.</p>
          <p>We’ll email you at <strong>{{ userEmail }}</strong> with a link to the .zip file when it’s ready. If you wish to use a different email address, you may change it on the settings page.</p>
          <p>By initiating the process to receive these files you are representing that you have all necessary rights and permissions to receive the content included in the file.</p>
          <v-btn
            large
            class="cta-btn mt-4 mb-4 mx-0"
            :disabled="exportSent"
            @click="exportData()"
          >
            Export
          </v-btn>
          <v-expand-transition>
            <p
              v-show="exportSent"
              class="font-italic"
              v-text="actionText"
            />
          </v-expand-transition>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'SettingsExport',
  metaInfo () {
    return {
      title: this.brandName
    }
  },
  props: {
    createAlert: {
      type: Function,
      default: function () {}
    }
  },
  data () {
    return {
      exportSent: false,
      isLoading: false,
      userEmail: this.$store.state.user.email,
      actionText: 'We received your export request. You\'ll get an email with download instructions soon.'
    }
  },
  computed: {
    ...mapState([
      'brandName'
    ])
  },
  beforeMount () {
    // Check if email is verified.
  },
  methods: {
    exportData () {
      this.isLoading = true
      this.$http.get('account/export')
        .then(() => {
          this.exportSent = true
        })
        .catch(err => {
          this.createAlert(err.data.errorMessage, 'warning', 'error')
          console.error(err)
        })
        .finally(() => {
          this.isLoading = false
        })
      this.exportSent = true
    },
    emailIsVerified () {
      // return Boolean according to validity of user's email
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  font-size: 20px;
}
</style>
